/* eslint-disable vue/valid-template-root */
<script>
const base64 = require("base-64");

export default {
  created() {
    const { encodeText } = this.$route.query;
    const decodeText = base64.decode(encodeText);
    const data1 = JSON.stringify(decodeText);

    localStorage.setItem("decodeText", data1);
    const accesToken = localStorage.getItem("decodeText");
    if (accesToken) {
      this.$router.push({ name: "viewUserList" });
    } else {
    }
  }
};
</script>
